<template>
  <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
    <div class="py-6">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-2xl font-semibold text-gray-900">IA</h1>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div class="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <documents
            :posts="posts.filter((item) => item.category.name == 'IA')"
          />
        </div>
        <!-- Replace with your content -->
        <div class="py-4">
          <div class="border-4 border-dashed border-gray-200 rounded-lg">
            <h1 class="text-2xl font-semibold text-gray-900 pb-6">
              Data IOT :
            </h1>
            <!-- <div class="hidden">{{ getData() }}</div> -->

            <table
              class="min-w-full divide-y divide-gray-200 overflow-y-auto h-10"
            >
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    id
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    timestamp
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    temperature
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    humidity
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    weather
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    luminosity
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    sensorId
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, itemx) in data_grid"
                  :key="item.id"
                  :class="itemx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.timestamp }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.temperature }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.humidity }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.weather }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.luminosity }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.sensorId }}
                  </td>
                </tr>
              </tbody>
            </table>
            <h1 class="py-6 text-2xl font-semibold text-gray-900 pb-6">
              Resultat
            </h1>
            Resultat = {{ productivity_score.data }}
          </div>
        </div>
        <!-- /End replace -->
      </div>
    </div>
  </main>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import documents from "../components/documents";
import { posts } from "../../public/documents/posts.js";
import axios from "axios";

export default {
  components: {
    documents,
  },
  setup() {
    return {
      posts,
    };
  },
  computed: {
    //
  },
  mounted: function () {
    this.IA();
    this.DATA();
  },
  data() {
    return {
      productivity_score: "..",
      data_grid: "..",
    };
  },
  methods: {
    async DATA() {
      axios
        .get("https://agile-tor-96378.herokuapp.com/api/reports")
        .then(async (response) => {
          this.data_grid = response.data;
        });
    },
    async IA() {
      let data = null;
      axios
        .get("https://agile-tor-96378.herokuapp.com/api/reports")
        .then(async (response) => {
          data = response.data[Object.keys(response).length - 1];
          this.productivity_score = await axios.post("http://127.0.0.1:5000/", {
            temperature: data.temperature,
            humidity: data.humidity,
            weather: data.weather.toLowerCase(),
            luminosity: data.luminosity,
            day: 1,
          });
        });
    },
  },
};
</script>
