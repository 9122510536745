import axios from "axios";

const state = {
  data: null,
  ia: null
};  

const getters = {
  stateData: (state) => state.data,
  stateIA: (state) => state.ia,
};

const actions = {
  async GetData({ commit }) {
    let response = await axios.get("reports");
    commit("setData", response.data);
  },
  async GetIA({ commit }) {
    let dataResponse = await axios.get("reports");
    let data = dataResponse.data[dataResponse.data.length - 1];
    let response = await axios.post("http://20.108.16.100:5000/", {
      temperature: data.temperature,
      humidity: data.humidity,
      weather: data.weather.toLowerCase(),
      luminosity: data.luminosity,
      day: 1
    });
    
    commit("setIA", response.productivity_score);
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setIA(state, data) {
    state.ia = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
