import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import axios from "axios";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = "https://agile-tor-96378.herokuapp.com/api/";

axios.interceptors.response.use(undefined, function(error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

createApp(App).use(store).use(router).mount('#app')
