<template>
<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
  <div class="relative bg-gray-900">
    <div class="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
      <div class="h-full w-full xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <img class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0" src="https://www.vinotrip.com/203-large_default/atelier-vignerons-alsace.jpg" />
          <div aria-hidden="true" class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r" />
        </div>
      </div>
    </div>
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <h2 class="text-sm font-semibold text-indigo-300 tracking-wide uppercase">
          T-MAJ-800 - Voltron
        </h2>
        <p class="mt-3 text-3xl font-extrabold text-white">Système de valorisation des cultures viticoles</p>
        <p class="mt-5 text-lg text-gray-300">
          Projet de mise en place d’un système de valorisation des cultures viticoles à l’usage du Syndicat des vignerons indépendants.
          Le but du projet est d’améliorer la qualité de suivi du cycle des cultures viticoles en proposants plusieurs solutions efficaces, ne dépassant pas le budget maximum et facile d’utilisation.
          Ces solutions doivent à terme pouvoir détecter les alertes environnementales (taux d’humidité trop bas ou trop élevé), la variation de température, les facteurs engageants à des maladies (champignons, parasites, insectes). Le stockage de données se fera sur des serveurs français comme l’exige le client. Les frais de transport occasionnés devront être inclus dans le prix forfaitaire.
        </p>
      </div>
    </div>
  </div>
    <div class=" bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
      <div>
        <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Documents</h2>
        <p class="mt-3 text-xl text-gray-500 sm:mt-4">
          Les documents apportent des précisions sur le déroulement du projet ainsi que sur les différentes spécialisations du groupe.
        </p>
        <p class="text-ml text-gray-500 sm:mt-4">
          Chaque document pourra être retrouvé dans sa spécialisation correspondante.
        </p>
      </div>
      <div class="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
        <documents :posts='posts' />
      </div>
    </div>
  </div>
</main>
</template>

<script>
import documents from "../components/documents";
import { posts } from "../../public/documents/posts.js";

export default {
  components: {
    documents
  },
  setup() {
    return {
      posts
    }
  },
}
</script>