export const posts = [
  {
    title: 'Mémoire Technique (POC)', 
    href: '/documents/Mémoire_Technique(POC).pdf',
    category: { name: 'MSI', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Mémoire Technique « Système de valorisation des cultures viticoles ».',
    author: {
      name: 'Lyna KARTOUT (MSI)',
    },
  },
  {
    title: 'Accompagnement au changement', 
    href: '/documents/AccopagnementAuChangement-Voltron25.pdf',
    category: { name: 'MSI', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Cette présentation PPT présente le projet et permet l\'accompagnement du changement face à notre client.',
    author: {
      name: 'Lyna KARTOUT (MSI)',
    },
  },
  {
    title: 'Declaration sur l\'honneur',
    href: '/documents/Declaration_sur_lhonneur.pdf',
    category: { name: 'MSI', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Attestation sur l\'honneur de la Directrice Technique',
    author: {
      name: 'Lyna KARTOUT (MSI)'
    },
  },
  {
    title: 'Lettre de reponse à l\'appel d\'offres',
    href: '/documents/Lettre_de_reponse_a_lappel_doffres.pdf',
    category: { name: 'MSI', color: 'bg-indigo-100 text-indigo-800' },
    description: 'Appel d’offres pour système de valorisation des cultures viticoles',
    author: {
      name: 'Lyna KARTOUT (MSI)'
    },
  },
  {
    title: 'Procédure d\'application PSSI',
    href: '/documents/Procedure_DApplication_PSSI.pdf',
    category: { name: 'CLOUD', color: 'bg-pink-100 text-pink-800' },
    description: 'Les mesures appliquées à la livraison du projet, afin de garantir la sécurité des systèmes informatique, conformément au PSSI et à la charte informatique liée.',
    author: {
      name: 'Hamza Ince (cloud)'
    },
  },
  {
    title: 'Cloud',
    href: '/documents/Voltron_Cloud_25.pdf',
    category: { name: 'CLOUD', color: 'bg-pink-100 text-pink-800' },
    description: 'Précision concernant la section Cloud',
    author: {
      name: 'Hamza Ince (cloud)'
    },
  },
  {
    title: 'Timeline',
    href: '/documents/Timeline.pdf',
    category: { name: 'MSI', color: 'bg-indigo-100 text-indigo-800' },
    description: 'Timeline avec le temps de développement de chaque spécialisation.',
    author: {
      name: 'Lyna KARTOUT (MSI)'
    },
  },
  {
    title: 'IoT',
    href: '/documents/Voltron_IoT.pdf',
    category: { name: 'IOT', color: 'bg-indigo-100 text-indigo-800' },
    description: 'Précision concernant la section IoT',
    author: {
      name: 'Damien & Andrei (IA)'
    },
  },
  {
    title: 'IA',
    href: '/documents/Voltron_rendu_IA.pdf',
    category: { name: 'IA', color: 'bg-green-100 text-green-800' },
    description: 'Précision concernant la section IA',
    author: {
      name: 'Antonio, Corentin, Léo, Oren (IA)'
    },
  },
  {
    title: 'PSSI Arthur Barthelme',
    href: '/documents/Voltron_PSSI_Arthur_Barthelme.pdf',
    category: { name: 'SECU', color: 'bg-pink-100 text-pink-800' },
    description: 'Politique de Sécurité des Systèmes d’Information (PSSI)',
    author: {
      name: 'Arthur (Sécu)'
    },
  },
  {
    title: 'Data',
    href: '/documents/Voltron_Data.pdf',
    category: { name: 'DATA', color: 'bg-pink-100 text-pink-800' },
    description: 'Précision concernant la section Data',
    author: {
      name: 'Rémi (Sécu)'
    },
  }
]
