import { createRouter, createWebHistory } from 'vue-router'
import store from "../store"
import Home from "../views/Home.vue"
import IOT from '../views/IOT.vue'
import IA from '../views/IA.vue'
import CLOUD from '../views/CLOUD.vue'
import DEVOPS from '../views/DEVOPS.vue'
import DATA from '../views/DATA.vue'
import SECU from '../views/SECU.vue'
import Error404 from '../views/Error/404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/IOT',
    name: 'IOT',
    component: IOT,
    meta: { requiresAuth: true },
  },
  {
    path: '/IA',
    name: 'IA',
    component: IA,
    meta: { requiresAuth: true },
  },
  {
    path: '/CLOUD',
    name: 'CLOUD',
    component: CLOUD,
    meta: { requiresAuth: true },
  },
  {
    path: '/DEVOPS',
    name: 'DEVOPS',
    component: DEVOPS,
    meta: { requiresAuth: true },
  },
  {
    path: '/DATA',
    name: 'DATA',
    component: DATA,
    meta: { requiresAuth: true },
  },
    {
    path: '/SECU',
    name: 'SECU',
    component: SECU,
    meta: { requiresAuth: true },
  },
  {
    path: '/404',
    name: 'Error404',
    component: Error404,
    meta: { guest: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
