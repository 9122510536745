<template>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
      <div class="py-6">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">IOT</h1>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

        <div class="grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          <documents :posts='posts.filter(item => item.category.name == "IOT")' />
        </div>

          
          <!-- Replace with your content -->
          <div class="py-4">
            <div class="border-4 border-dashed border-gray-200 rounded-lg h-96">
              
            </div>
          </div>
          <!-- /End replace -->
        </div>
      </div>
    </main>
</template>

<script>
import documents from "../components/documents";
import { posts } from "../../public/documents/posts.js";

export default {
  components: {
    documents
  },
  setup() {
    return {
      posts
    }
  },
}
</script>