<template>
  <div v-for="post in posts" :key="post.title">
    <div>
      <div target="_blank" class="inline-block">
        <span
          :class="[
            post.category.color,
            'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
          ]"
        >
          {{ post.category.name }}
        </span>
      </div>
    </div>
    <a :href="post.href" target="_blank" class="block mt-4">
      <p class="text-xl font-semibold text-gray-900">
        {{ post.title }}
      </p>
      <p class="mt-3 text-base text-gray-500">
        {{ post.description }}
      </p>
    </a>
    <div class="mt-6 flex items-center">
      <div class="ml-1">
        <p class="text-sm font-medium text-gray-900">
          <a :href="post.author.href">
            {{ post.author.name }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "documents",
  props: ["posts"],
};
</script>